import './App.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClose, faCross, faRotate, faSearch} from '@fortawesome/free-solid-svg-icons'
import {useEffect, useRef, useState} from "react";
import useLocalStorage from "./hooks/useLocalStorage";
import {PulseLoader} from "react-spinners";
import {debounce} from "lodash";
import Job from './schemas/job';

function App() {
  const [tickets, setTickets] = useLocalStorage('tickets', []);
  const [dataToSync, setDataToSync] = useLocalStorage('dataToSync', {});
  const [searchTickets, setSearchTickets] = useState(undefined);
  const [isSyncing, setIsSyncing] = useState(false);
  const [hasInternet, setHasInternet] = useState(false);
  const [ticketCount, setTicketCount] = useLocalStorage('ticketCount', 0);
  const [eventId, setEventId] = useLocalStorage('eventId', 0);
  const [attendCount, setAttendCount] = useLocalStorage('attendCount', 0);
  const [eventList, setEventList] = useLocalStorage('eventList', []);
  const [cacheVersion, setCacheVersion] = useLocalStorage('cacheVersion', 'nocache');
  const transactionId = useRef();
  const eventListSelect = useRef();

  useEffect(() => {
      getEventsList();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  useEffect(() => {
    checkInternetConnection();
    const interval = setInterval(() => {
      checkInternetConnection();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  /*useEffect(() => {
    console.log('Sync Data Change Detected');
    if (Object.keys(dataToSync).length === 0) {
      console.log('Nothing to sync');
    } else {
      checkInternetConnection();
    }
  }, [dataToSync]);*/

  const checkInternetConnection = () => {
    console.log('Checking Net Connection');
    if (navigator.onLine) {
      isReachable('https://www.ukrunningevents.co.uk').then(function (online) {
        if (online) {
          setHasInternet(true);
          /*if (Object.keys(dataToSync).length === 0) {
            console.log('No data to sync, grab a new copy');
            handleSync();
          } else {
            console.log('Process the queue');
            processQueue();
          }*/
        } else {
          setHasInternet(false);
        }
      });
    } else {
      setHasInternet(false);
    }
  };

  const processQueue = async () => {
    console.log('Processing Job Queue');
    setIsSyncing(true);
    const data = {
      jobs: dataToSync,
      event_id: eventId
    };

    const response = await fetch("https://www.ukrunningevents.co.uk/api/store_attended", {
      method: "POST",
      cache: 'no-cache',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
      .then((res) => {
        return res.json();
      })
      .then(
        (result) => {
          //console.log(result);
          setTickets(result.tickets);
          setTicketCount(result.ticketCount);
          setCacheVersion(result.cacheVersion);
          setIsSyncing(false);
          debouncedSearch();
          updateCount();
          checkJobQueueStatus(result.tickets);
        });
  };

  const checkJobQueueStatus = (ts) => {
    // Scan job queue and make sure all items are updated - remove if they are...
    /*let syncData = {...dataToSync};
    let toRemove = [];
    Object.keys(syncData).map((key) => {
      ts[syncData[key]._transaction_id].map((t, i) => {
        //console.log(t.packed_by, syncData[key]._packed_by);
        if (t.packed_by === syncData[key]._packed_by) {
          toRemove.push(key);
        }
      });
    });
    toRemove.map((key) => {
      delete syncData[key];
    });*/
    setDataToSync({});
  };

  const isReachable = (url) => {
    return fetch(url, {method: 'HEAD', mode: 'no-cors'})
      .then(function (resp) {
        return resp && (resp.ok || resp.type === 'opaque');
      })
      .catch(function (err) {
        console.warn('[conn test failure]:', err);
      });
  }

  const getEventsList = () => {
    fetch('https://www.ukrunningevents.co.uk/api/i5k_events')
      .then((res) => {
        return res.json();
      })
      .then((result) => {
          setEventList(result);
          setEventId(result[0].event_id)
        }
      ).then(() => {
      checkInternetConnection();
    });
  }

  const debouncedSearch = debounce(async () => {
    setSearchTickets(tickets[transactionId.current.value]);
    transactionId.current.select();
    checkInternetConnection();
  }, 500);

  const handleSearch = async (e) => {
    e.preventDefault();
    debouncedSearch();
  }

  const handleSync = (e) => {
    if (e !== undefined && e !== null) {
      e.preventDefault();
    }

    if (Object.keys(dataToSync).length > 0) {
      processQueue();
    } else {
      console.log('Syncing Entrants List');
      setIsSyncing(true);

      fetch('https://www.ukrunningevents.co.uk/api/entrant_list/' + eventId)
        .then((res) => {
          return res.json();
        })
        .then((result) => {
            console.log(result);
            setTickets(result.tickets);
            setTicketCount(result.ticketCount);
            setCacheVersion(result.cacheVersion);
            updateCount();
          }
        )
        .then(() => {
          setIsSyncing(false);
        });
    }
  }

  const updateEventId = (e) => {
    e.preventDefault();
    setEventId(eventListSelect.current.value * 1);
  }

  const getEventSelector = () => {
    if (eventList.length > 0) {
      return (<select name={'event'} ref={eventListSelect} value={eventId} onChange={updateEventId}
                      className={"text-sm rounded"}>
        {eventList.map((e, i) => {
          return (<option key={i} value={e.event_id}>{e.title} - {e.date}</option>);
        })}
      </select>);
    } else {
      return (<div>Events Not Synced</div>);
    }
  }

  const updateCount = () => {
    let cnt = 0;
    Object.keys(tickets).map((key, i) => {
      tickets[key].map((t, i) => {
        if (t.packed_by !== 0) {
          cnt++;
        }
        return true;
      });
      return true;
    });
    setAttendCount(cnt);
  };

  const addJob = async (jobs) => {
    console.log('Adding Job')
    const jq = {...dataToSync};
    jobs.map((t) => {
      let j = new Job();
      j.packed_by = t.packed_by;
      j.ticket_id = t.ticket_id;
      j.transaction_id = t.transaction_id;
      j.unix_timestamp = Date.now();
      jq[t.ticket_id] = j;
    });
    await setDataToSync(jq);
  }

  const toggleAll = async (packed_by) => {
    console.log('Toggle All')
    let jobs = [];
    let tempTickets = {...tickets};
    tempTickets[transactionId.current.value].map(async (t, i) => {
      tempTickets[transactionId.current.value][i].packed_by = packed_by;
      jobs.push(tempTickets[transactionId.current.value][i]);
      return true;
    });
    setTickets(tempTickets);

    let tempSeachTickets = [...searchTickets];
    tempSeachTickets.map((t, i) => {
      tempSeachTickets[i].packed_by = packed_by;
      return true;
    });
    setSearchTickets(tempSeachTickets);

    updateCount();

    transactionId.current.select();

    await addJob(jobs);
  };

  const toggleChecked = async (index) => {
    console.log('Toggle One')
    let jobs = [];
    const newValue = searchTickets[index].packed_by === 0 ? 1 : 0;

    let tempTickets = {...tickets};
    tempTickets[transactionId.current.value][index].packed_by = newValue;
    jobs.push(tempTickets[transactionId.current.value][index]);
    setTickets(tempTickets);

    let tempSeachTickets = [...searchTickets];
    tempSeachTickets[index].packed_by = newValue;
    setSearchTickets(tempSeachTickets);

    updateCount();

    transactionId.current.select();

    await addJob(jobs);
  };

  const handleFocus = (event) => event.target.select();

  const getTickets = () => {
    if (searchTickets !== undefined) {
      let distances = {
        '2.5k': 0,
        '5k': 0,
        '10k': 0,
        '15k': 0
      };

      const ticketList = searchTickets.map((ti, i) => {
        if (ti.packed_by !== 0) {
          distances[ti.distance]++;
        }
        return (
          <tr key={i}>
            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-4xl font-medium sm:pl-3 text-center cursor-pointer"
                onClick={() => toggleChecked(i)}>
              {ti.packed_by === 0 ? <FontAwesomeIcon icon={faCheck} className={'text-gray-300'}/>
                : <FontAwesomeIcon icon={faCheck} className={'text-ukre-red'}/>}
            </td>
            <td className="whitespace-nowrap px-8 py-2 text-xl text-gray-500">
              {ti.first_name ? ti.first_name + ' ' + ti.last_name : (
                <div className={'font-bold text-ukre-red'}>SEND TO HELPDESK - NO NAME</div>)}
            </td>
            <td className="whitespace-nowrap px-8 py-2 text-xl text-gray-500">{ti.distance}</td>
            <td
              className="whitespace-nowrap px-8 py-2 text-xl text-gray-500">{ti.time_slot}
            </td>
          </tr>);
      });

      const distanceList = (
        <div className={'grid grid-cols-4 gap-4'}>
          <div
            className={`p-4 pt-5 rounded-xl font-bold text-5xl text-center ${distances["2.5k"] > 0 ? 'bg-ukre-yellow text-black' : 'bg-gray-300 text-gray-400'}`}>
            <div className={'text-lg font-zorque'}>2.5K</div>
            <div className={'text-5xl'}>{distances["2.5k"]}</div>
          </div>

          <div
            className={`p-4 pt-5 rounded-xl font-bold text-5xl text-center ${distances["5k"] > 0 ? 'bg-ukre-red text-white' : 'bg-gray-300 text-gray-400'}`}>
            <div className={'text-lg font-zorque'}>5K</div>
            <div className={'text-5xl'}>{distances["5k"]}</div>
          </div>

          <div
            className={`p-4 pt-5 rounded-xl font-bold text-5xl text-center ${distances["10k"] > 0 ? 'bg-ukre-green text-white' : 'bg-gray-300 text-gray-400'}`}>
            <div className={'text-lg font-zorque'}>10K</div>
            <div className={'text-5xl'}>{distances["10k"]}</div>
          </div>

          <div
            className={`p-4 pt-5 rounded-xl font-bold text-5xl text-center ${distances["15k"] > 0 ? 'bg-ukre-blue text-white' : 'bg-gray-300 text-gray-400'}`}>
            <div className={'text-lg font-zorque'}>15K</div>
            <div className={'text-5xl'}>{distances["15k"]}</div>
          </div>
        </div>
      );

      return (<div className={'flex flex-col gap-4'}>
        <div className={'w-full'}>{distanceList}</div>
        <div className={'w-full'}>
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
            <tr className="bg-ukre-red">
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xl font-semibold text-white sm:pl-3">Attended
              </th>
              <th scope="col" className="px-8 py-3.5 text-left text-xl font-semibold text-white">Name</th>
              <th scope="col" className="px-8 py-3.5 text-left text-xl font-semibold text-white">Distance</th>
              <th scope="col" className="px-8 py-3.5 text-left text-xl font-semibold text-white">Time Slot</th>
            </tr>
            </thead>
            <tbody className="bg-white">
            {ticketList}
            </tbody>
          </table>

        </div>
      </div>)
    } else {
      return (<div className={'text-center text-xl text-gray-400'}>No tickets found</div>);
    }
  }

  return (
    <div>
      {hasInternet ?
        <div className={'bg-ukre-green text-white w-full text-sm text-center mb-6 font-bold'}>Internet Connection
          Available</div> :
        <div className={'bg-ukre-red text-white w-full text-sm text-center mb-6 font-bold'}>No Internet
          Connection</div>}

      <div className="flex flex-col gap-4 w-full px-2 md:max-w-7xl mx-auto">
        <div className={'flex flex-col md:flex-row gap-4 md:gap-2 justify-between md:items-center'}>
          <div className={'text-sm flex flex-col'}>
            <div>{ticketCount} ticket(s) cached</div>
            <div>{cacheVersion}</div>
            <div>{attendCount} tickets redeemed</div>
          </div>
          {hasInternet ?
            <div>
              {isSyncing ?
                <div
                  className={'font-bold flex flex-row justify-between items-center gap-2 py-2 px-4 border border-gray-300 rounded bg-ukre-red text-white cursor-pointer'}>
                  Retrieving Data <PulseLoader color="#FFFFFF"/>
                </div>
                :
                <div className={'flex flex-col md:flex-row gap-2'}>
                  {getEventSelector()}
                  <div onClick={handleSync}
                       className={'font-bold flex flex-row justify-between items-center gap-2 py-2 px-4 border border-gray-300 rounded bg-ukre-red text-white cursor-pointer'}>
                    Retrieve Data <FontAwesomeIcon icon={faRotate}/>
                  </div>
                </div>
              }
            </div>
            : <div className={'font-bold text-right'}>Internet connection required for data download.</div>}
        </div>

        {ticketCount > 0 ?
          <form method="post" onSubmit={handleSearch} className={'flex flex-row gap-4 mt-4'}>
            <input className={'border border-gray-300 rounded w-full px-4 py-1'}
                   placeholder={'Transaction ID'}
                   name="transactionId"
                   defaultValue=""
                   ref={transactionId}
                   onChange={handleSearch}
                   onFocus={handleFocus}
                   onClick={handleFocus}
            />
            <button
              className={'font-bold flex flex-row justify-center items-center gap-2 py-2 px-4 border border-gray-300 rounded bg-ukre-red text-white cursor-pointer'}
              type="submit"><FontAwesomeIcon icon={faSearch}/> Search
            </button>
          </form> :
          <div className={'text-center text-gray-400'}>You must download a dataset before you can search. Choose an
            event
            above and click retrieve data.</div>
        }

        {searchTickets !== undefined ?
          <div className={'flex flex-row justify-center items-center'}>
            <button
              className={'font-bold flex flex-row justify-center items-center gap-2 py-2 px-4 border border-gray-300 rounded bg-ukre-red text-white cursor-pointer'}
              onClick={() => toggleAll(1)}><FontAwesomeIcon icon={faCheck}/> Check All
            </button>
            <button
              className={'font-bold flex flex-row justify-center items-center gap-2 py-2 px-4 border border-gray-300 rounded bg-ukre-red text-white cursor-pointer'}
              onClick={() => toggleAll(0)}><FontAwesomeIcon icon={faClose}/> Uncheck All
            </button>
          </div>
          : null}

        {ticketCount > 0 ? <div>
          {getTickets()}
        </div> : null}
      </div>
    </div>
  );
}

export default App;
