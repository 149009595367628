export default class Job {
  get transaction_id() {
    return this._transaction_id;
  }

  set transaction_id(value) {
    this._transaction_id = value;
  }
  get unix_timestamp() {
    return this._unix_timestamp;
  }

  set unix_timestamp(value) {
    this._unix_timestamp = value;
  }
  get packed_by() {
    return this._packed_by;
  }

  set packed_by(value) {
    this._packed_by = value;
  }
  get ticket_id() {
    return this._ticket_id;
  }

  set ticket_id(value) {
    this._ticket_id = value;
  }
  constructor() {
    this._packed_by = '';
    this._ticket_id = 0;
    this._transaction_id = '';
    this._unix_timestamp = null;
  }
}